import React from 'react';
// import Service from 'views/Service';
import Main from 'layouts/Main';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';

const DownloadPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        sx={{
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          position: 'relative',
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container position={'relative'} zIndex={3}>
          <p>Download</p>
        </Container>
      </Box>
    </Main>
  );
};

export default DownloadPage;
